<style lang="scss" scoped>
.custom-table {
    .table-item {
        width: 100%;
        height: 34px;
        display: flex;
        align-items: center;
        font-size: 14px;
        .table-row {
            width: 240px;
            flex-shrink: 0;
            padding-left: 16px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
        }

        .table-row1 {
            flex-grow: 1;
            padding-left: 0;
            color: rgba(0, 0, 0, 0.6);
        }

    }

    .table-item-head {
        background: linear-gradient(90deg, rgba(0, 122, 255, 0.4) 0%, rgba(0, 178, 255, 0.4) 100%);

        .table-row {
            color: white !Important;
        }

    }
    .double{
        background: rgb(255, 255, 255);
    }
    .one{
        background: #F0F0F0;
    }       
}
</style>

<template>
    <div class="custom-table">
        <div class="table-item table-item-head">
            <div class="table-row">{{label1}}</div>
            <div class="table-row table-row1">{{ label2 }}</div>
        </div>
        <div class="table-item" v-for="(v,u) of data" :class="u%2==0?'double':'one'">
            <div class="table-row">{{v[0]}}</div>
            <div class="table-row table-row1">{{ v[1] }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "custom-table",
    props: {
        data: Array,
        label1: String,
        label2: String
    }
}

</script>