<style lang="scss">
.loading {
  position: fixed;
  left: 0;
  top: 40px;
  z-index: 3;
  background: #f8f8f8;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loading-text {
    font-size: 18px;
    color: rgba(1, 153, 153, 1);
  }
  .loading-icon {
    font-size: 40px;
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.8);
    background-image: url("./loading.png");
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div class="loading">
    <div class="loading-icon"></div>

    <div class="loading-text">
      加载中
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
};
</script>
