/**
 * @description 路由信息
 */

export default [
  {
    name: "index",
    path: "/",
    component: () => import("@/views/index"),
    meta: {
      name: "首页",
      time() {
        return 0;
      },
    },
    redirect: "/feature",
    children: [
      // {
      //     name: "details",
      //     path: "/details",
      //     component: () => import("@/views/index/views/details"),
      // },
      {
        name: "feature",
        path: "/feature",
        component: () => import("@/views/index/views/feature"),
      },
      {
        name: "parameter",
        path: "/parameter",
        component: () => import("@/views/index/views/parameter"),
      },
      {
        name: "structure",
        path: "/structure",
        component: () => import("@/views/index/views/structure"),
      },
    ],
  },
];
