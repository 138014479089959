<style lang="scss">
.custom-box {
    width: 468px;
    background: #FFFFFF;
    border-radius: 8px;

    .header {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        
        .line {
            width: 8px;
            height: 25px;
            background: #007AFF;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        .text {
            margin-left: 12px;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.8);
            font-weight: bold;
        }
    }

    .main {
        padding-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }
}
</style>

<template>
    <div class="custom-box">
        <div class="header">
            <div class="line"></div>
            <div class="text">{{ title }}</div>
        </div>
        <div class="main">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "custom-box",
    props: {
        title: String
    }
}
</script>