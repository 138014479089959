<style lang="scss" scoped>
body {
  margin: 0 auto;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgb(194, 194, 194);
}
</style>

<template>
  <div id="app" @dragstart.prevent @selectstart.prevent>
        <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
